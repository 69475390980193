import MonacoEditor from '@monaco-editor/react';
import React, { useEffect, useRef, useState } from 'react';
import { lamTransformPrompt, suggestionsPrompt } from './prompts';

const CodeBlock = ({ code, language, onApply }) => (
    <div className="rounded-lg overflow-hidden shadow-md border border-gray-200 bg-white">
        <div className="bg-gray-50 px-4 py-2 border-b border-gray-200 flex justify-between items-center">
            <span className="text-xs font-medium text-gray-600">{language}</span>
            {onApply && (
                <button
                    onClick={() => onApply(code)}
                    className="px-3 py-1 text-sm bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                >
                    Apply
                </button>
            )}
        </div>
        <MonacoEditor
            height="200px"
            language={language}
            theme="vs-light"
            value={code}
            options={{
                readOnly: true,
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                folding: false,
                lineNumbers: 'on',
                wordWrap: 'on',
                contextmenu: false,
                fontSize: 14,
                renderLineHighlight: 'all',
                scrollbar: {
                    vertical: 'visible',
                    horizontal: 'visible',
                    verticalScrollbarSize: 8,
                    horizontalScrollbarSize: 8,
                },
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                overviewRulerBorder: false,
                padding: { top: 8, bottom: 8 }
            }}
        />
    </div>
);

const MessageContent = ({ content, onTransformationSuggested }) => {
    // Update regex to be more lenient with whitespace and language identifiers
    const codeBlockRegex = /```(?:javascript|js|jq|json)?\s*([\s\S]*?)```/g;
    const inlineCodeRegex = /`([^`]+)`/g;
    const boldRegex = /\*\*([^*]+)\*\*/g;
    const italicRegex = /\_([^_]+)\_/g;
    const bulletRegex = /^[\s]*[-*•][\s]+(.+)$/gm;

    const parts = [];
    let lastIndex = 0;
    let match;

    // Process code blocks first
    while ((match = codeBlockRegex.exec(content)) !== null) {
        // Add text before code block
        if (match.index > lastIndex) {
            parts.push({
                type: 'text',
                content: content.slice(lastIndex, match.index)
            });
        }

        // Add code block
        parts.push({
            type: 'code',
            language: 'javascript', // Default to javascript
            content: match[1].trim()
        });

        lastIndex = match.index + match[0].length;
    }

    // Add remaining text
    if (lastIndex < content.length) {
        parts.push({
            type: 'text',
            content: content.slice(lastIndex)
        });
    }

    return (
        <div className="space-y-3">
            {parts.map((part, index) =>
                part.type === 'code' ? (
                    <div key={index} className="relative group">
                        <CodeBlock
                            code={part.content}
                            language={part.language}
                            onApply={onTransformationSuggested}
                        />
                    </div>
                ) : (
                    <div key={index} className="prose prose-sm max-w-none">
                        {part.content.split('\n').map((line, i) => {
                            // Process inline markdown
                            let processedLine = line
                                .replace(inlineCodeRegex, '<code class="bg-gray-100 text-sm px-1.5 py-0.5 rounded">$1</code>')
                                .replace(boldRegex, '<strong>$1</strong>')
                                .replace(italicRegex, '<em>$1</em>')
                                .replace(bulletRegex, '<li>$1</li>');

                            return (
                                <p
                                    key={i}
                                    className="text-gray-700 leading-relaxed mb-2"
                                    dangerouslySetInnerHTML={{ __html: processedLine }}
                                />
                            );
                        })}
                    </div>
                )
            )}
        </div>
    );
};

const WelcomeScreen = ({ onSubmit, onContactUs }) => {
    const [email, setEmail] = useState('');
    const isValidEmail = email.includes('@') && email.includes('.');

    return (
        <div className="p-6 text-center">
            <h3 className="text-xl font-semibold mb-4">Meet LAMI</h3>
            <p className="text-gray-600 mb-6">
                Your AI-powered data transformation assistant. LAMI helps you write, understand,
                and optimize data transformations in JQ and JavaScript.
            </p>
            <div className="space-y-4">
                <button
                    onClick={onContactUs}
                    className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 shadow-sm transition-colors"
                >
                    Schedule a Demo
                </button>
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">or</span>
                    </div>
                </div>
                <div>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email to continue"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="mt-2 text-xs text-gray-500">
                        We'll use this to keep you updated about new features and improvements
                    </p>
                </div>
                {isValidEmail && (
                    <button
                        onClick={() => onSubmit(email)}
                        className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 shadow-sm transition-colors"
                    >
                        Start Using LAMI →
                    </button>
                )}
            </div>
        </div>
    );
};

const SuggestedPrompts = ({ onSelect, inputJson, language, email, onTransformApplied, apiStatus, output, transformation }) => {
    const [showContext, setShowContext] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Only generate suggestions if we have both email and inputJson
        if (email && inputJson) {
            // Add a small delay to ensure all state is properly initialized
            const timer = setTimeout(() => {
                generateSuggestions();
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [inputJson, language, email, apiStatus, output]);

    const generateSuggestions = async () => {
        // Don't generate if we're missing required data
        if (!email || !inputJson) {
            console.log('Missing required data for suggestions:', { email, inputJson });
            return;
        }

        setLoading(true);
        setError(null);
        setSuggestions([]); // Clear existing suggestions while loading
        try {
            let contextMessage = `Language: ${language}\nInput data: ${inputJson}\n`;

            if (apiStatus.error) {
                contextMessage += `\nTransformation error occurred: ${apiStatus.error}\n`;
                contextMessage += "\nGenerate 3 suggestions to fix this error.";
            } else if (output?.result) {
                contextMessage += `\nCurrent output: ${JSON.stringify(output.result, null, 2)}\n`;
                contextMessage += "\nGenerate 3 suggestions for further transforming or improving this data.";
            } else {
                contextMessage += "\nGenerate 3 specific suggestions for transforming this data.";
            }

            const response = await fetch('https://lamapi3.azurewebsites.net/lami/public', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    input: contextMessage,
                    inputJson,
                    language,
                    existingMessages: [
                        { role: "assistant", content: suggestionsPrompt }
                    ],
                    apiStatus: {
                        error: apiStatus.error,
                    },
                    output: {
                        result: output?.result ? JSON.stringify(output.result) : null,
                        log: output?.log || null,
                    },
                })
            });

            if (!response.ok) {
                const errorData = await response.text();
                try {
                    const jsonError = JSON.parse(errorData);
                    if (jsonError.error?.includes('Rate limit exceeded')) {
                        setSuggestions([
                            'Schedule a demo for unlimited access',
                            'Try again in a few minutes',
                            'Contact support for help'
                        ]);
                        setError('Rate limit exceeded. Please schedule a demo for unlimited access.');
                        return;
                    }
                    throw new Error(jsonError.error || 'Failed to generate suggestions');
                } catch (e) {
                    throw new Error(errorData || 'Failed to generate suggestions');
                }
            }

            const data = await response.json();

            // Only set suggestions if we get them from the API
            if (data.suggestions && data.suggestions.length > 0) {
                setSuggestions(data.suggestions);
            }
        } catch (error) {
            console.error('Error generating suggestions:', error);
            setError(error.message || 'Failed to generate suggestions');
            setSuggestions([
                'Try refreshing the page',
                'Check your input data',
                'Contact support if the issue persists'
            ]);
        } finally {
            setLoading(false);
        }
    };

    const contextInfo = {
        currentSetup: {
            language,
            dataPreview: inputJson ? JSON.parse(inputJson) : null,
        },
        aiCapabilities: [
            "✨ Understands complex data structures",
            "🔄 Generates both JQ and JavaScript transforms",
            "📊 Handles arrays, objects, and nested data",
            "🎯 Creates targeted field extractions",
            "🔍 Provides explanations and examples"
        ]
    };

    return (
        <div className="space-y-2">
            <div className="flex items-center justify-between mb-2">
                <div className="flex-1">
                    {loading ? (
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
                            AI is generating suggestions...
                        </div>
                    ) : error ? (
                        <div className="text-sm text-red-600 mb-2">{error}</div>
                    ) : (
                        <div className="flex flex-wrap gap-2">
                            {suggestions.map((suggestion, index) => (
                                <button
                                    key={index}
                                    onClick={() => onSelect(suggestion)}
                                    className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 transition-colors"
                                >
                                    {suggestion}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <div className="relative ml-2">
                    <button
                        onClick={() => setShowContext(!showContext)}
                        className="text-gray-500 hover:text-gray-700 flex items-center gap-1 text-sm"
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Context
                    </button>
                    {showContext && (
                        <div className="absolute bottom-full right-0 mb-2 w-96 bg-white rounded-lg shadow-xl border border-blue-100 text-sm">
                            <div className="bg-gradient-to-r from-blue-50 to-white px-4 py-2 rounded-t-lg border-b border-blue-100 flex justify-between items-center">
                                <div>
                                    <h4 className="font-medium text-blue-700">AI Assistant Context</h4>
                                    <p className="text-xs text-gray-600">Information being used to assist you</p>
                                </div>
                                <button
                                    onClick={() => setShowContext(false)}
                                    className="text-gray-500 hover:text-gray-700 p-1 rounded-lg hover:bg-gray-100"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="p-4 space-y-4">
                                <div>
                                    <h4 className="font-medium text-blue-600 mb-2 flex items-center gap-2">
                                        <span>Current Environment</span>
                                        <span className="text-xs px-2 py-0.5 bg-blue-100 text-blue-700 rounded">Active</span>
                                    </h4>
                                    <div className="space-y-2 bg-gray-50 p-3 rounded-lg">
                                        <p><span className="font-medium">Language:</span> {language}</p>
                                        <div>
                                            <p className="font-medium mb-1">Input Data:</p>
                                            <pre className="text-xs bg-white p-2 rounded border border-gray-100 overflow-auto max-h-24">
                                                {JSON.stringify(contextInfo.currentSetup.dataPreview, null, 2)}
                                            </pre>
                                        </div>
                                        <div>
                                            <p className="font-medium mb-1">Transformation:</p>
                                            <pre className="text-xs bg-white p-2 rounded border border-gray-100 overflow-auto max-h-24">
                                                <code className="bg-gray-100 text-sm px-1.5 py-0.5 rounded font-mono text-gray-800">
                                                    {transformation}
                                                </code>
                                            </pre>
                                        </div>
                                        {output?.result && (
                                            <div>
                                                <p className="font-medium mb-1">Current Output:</p>
                                                <pre className="text-xs bg-white p-2 rounded border border-gray-100 overflow-auto max-h-24">
                                                    {JSON.stringify(output.result, null, 2)}
                                                </pre>
                                            </div>
                                        )}
                                        {output?.log && (
                                            <div>
                                                <p className="font-medium mb-1">Execution Logs:</p>
                                                <pre className="text-xs bg-white p-2 rounded border border-gray-100 overflow-auto max-h-24">
                                                    {output.log}
                                                </pre>
                                            </div>
                                        )}
                                        {apiStatus?.error && (
                                            <div>
                                                <p className="font-medium mb-1 text-red-600">Error:</p>
                                                <pre className="text-xs bg-red-50 text-red-700 p-2 rounded border border-red-100 overflow-auto max-h-24">
                                                    {apiStatus.error}
                                                </pre>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <h4 className="font-medium text-blue-600 mb-2">AI Capabilities</h4>
                                    <ul className="space-y-1 bg-gray-50 p-3 rounded-lg">
                                        {contextInfo.aiCapabilities.map((cap, i) => (
                                            <li key={i} className="text-sm flex items-center gap-2">
                                                <span>{cap}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const PulseButton = ({ onClick, children, shouldPulse }) => (
    <button
        onClick={onClick}
        className={`px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors
            ${shouldPulse ? 'animate-pulse' : ''}`}
    >
        {children}
    </button>
);

const AIChatPanel = ({
    isOpen: propIsOpen = false,
    onClose,
    language,
    inputJson,
    onTransformationSuggested,
    output,
    apiStatus,
    transformation
}) => {
    const [isOpen, setIsOpen] = useState(propIsOpen);
    const [messages, setMessages] = useState(() => {
        const saved = localStorage.getItem('aiChatMessages');
        return saved ? JSON.parse(saved) : [];
    });
    const [input, setInput] = useState('');
    const [usageCount, setUsageCount] = useState(0);
    const [showCalendly, setShowCalendly] = useState(false);
    const [email, setEmail] = useState('');
    const [isConfiguring, setIsConfiguring] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldPulseRun, setShouldPulseRun] = useState(false);
    const messagesEndRef = useRef(null);
    const calendlyRef = useRef(null);

    // Sync with prop
    useEffect(() => {
        setIsOpen(propIsOpen);
    }, [propIsOpen]);

    const handleClose = () => {
        setIsOpen(false);
        if (onClose) onClose();
    };

    const buttonClasses = `fixed right-4 bottom-4 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700
            ${!isOpen ? 'animate-bounce' : ''}`;

    useEffect(() => {
        localStorage.setItem('aiChatMessages', JSON.stringify(messages));
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        const savedCount = localStorage.getItem('aiChatUsageCount');
        const lastReset = localStorage.getItem('aiChatLastReset');

        if (lastReset && Date.now() - parseInt(lastReset) > 24 * 60 * 60 * 1000) {
            localStorage.setItem('aiChatUsageCount', '0');
            localStorage.setItem('aiChatLastReset', Date.now().toString());
            setUsageCount(0);
        } else if (savedCount) {
            setUsageCount(parseInt(savedCount));
        }
    }, []);

    const handleEmailSubmit = (newEmail) => {
        setEmail(newEmail);
        if (newEmail && newEmail.includes('@')) {
            sessionStorage.setItem('lami_email', newEmail);

            // PostHog
            if (window.posthog) {
                window.posthog.identify(newEmail);
                window.posthog.people.set({ email: newEmail });
                window.posthog.capture('lami_email_submitted', {
                    email: newEmail
                });
            }
            setIsConfiguring(false);
        }
    };

    const clearHistory = () => {
        setMessages([]);
        localStorage.removeItem('aiChatMessages');
    };

    const handleTransformationApplied = (transformation) => {
        if (onTransformationSuggested) {
            onTransformationSuggested(transformation);
            setShouldPulseRun(true);
            setTimeout(() => setShouldPulseRun(false), 3000);
        }
    };

    const handleScheduleDemo = () => {
        if (window.posthog) {
            window.posthog.capture('schedule_demo_clicked', {
                location: 'chat_panel',
                email: email || 'not_provided'
            });
            if (email) {
                window.posthog.identify(email);
                window.posthog.people.set({ email: email });
            }
        }
        setShowCalendly(true);
    };

    const handleSend = async () => {
        if (!input.trim() || !email || isLoading) return;

        if (usageCount >= 5 && !isLocalhost()) {
            setShowCalendly(true);
            return;
        }

        const newMessages = [...messages, { role: 'user', content: input }];
        setMessages(newMessages);
        setInput('');
        setIsLoading(true);

        const newCount = usageCount + 1;
        setUsageCount(newCount);
        if (!isLocalhost()) {
            localStorage.setItem('aiChatUsageCount', newCount.toString());
            if (!localStorage.getItem('aiChatLastReset')) {
                localStorage.setItem('aiChatLastReset', Date.now().toString());
            }
        }

        try {
            let contextMessage = `Context for transformation:\n\n`;
            contextMessage += `1. Language: ${language}\n`;
            contextMessage += `2. Input Data: ${JSON.stringify(inputJson, null, 2)}\n`;

            if (apiStatus.error) {
                contextMessage += `3. Error State:\n   ${apiStatus.error}\n`;
            }

            if (output?.result) {
                contextMessage += `4. Current Output:\n   ${JSON.stringify(output.result, null, 2)}\n`;
            }

            if (output?.log) {
                contextMessage += `5. Execution Logs:\n   ${output.log}\n`;
            }

            contextMessage += `\nBased on the above context, please address this request: ${input}\n`;
            contextMessage += `Note: If you reference any logs or errors in your response, please explicitly state which parts informed your conclusion.`;

            const response = await fetch('https://lamapi3.azurewebsites.net/lami/public', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    input: contextMessage,
                    inputJson,
                    language,
                    existingMessages: [
                        { role: "assistant", content: lamTransformPrompt },
                        ...messages
                    ],
                    apiStatus: {
                        error: apiStatus.error,
                    },
                    output: {
                        result: output?.result ? JSON.stringify(output.result) : null,
                        log: output?.log || null,
                    },
                })
            });

            const data = await response.json();

            // Check for rate limit error
            if (data.error && data.error.includes('Rate limit exceeded')) {
                setMessages([...newMessages, {
                    role: 'assistant',
                    content: "You've reached the rate limit for AI assistance. Please schedule a demo to continue using LAMI with unlimited access."
                }]);
                setShowCalendly(true);
                return;
            }

            // Set the full response message without auto-applying transformation
            setMessages([...newMessages, {
                role: 'assistant',
                content: data.fullResponse || data.message || 'No response received'
            }]);
        } catch (error) {
            console.error('Error:', error);
            setMessages([...newMessages, {
                role: 'assistant',
                content: `Error: ${error.message || 'There was an error processing your request.'}`
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const isLocalhost = () => {
        return window.location.hostname === 'localhost' ||
            window.location.hostname === '127.0.0.1';
    };

    useEffect(() => {
        if (showCalendly && calendlyRef.current) {
            const script = document.createElement('script');
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.async = true;

            const initCalendly = () => {
                if (window.Calendly && calendlyRef.current) {
                    window.Calendly.initInlineWidget({
                        url: 'https://calendly.com/faiz-laminar/chat-with-faiz-site?hide_gdpr_banner=1',
                        parentElement: calendlyRef.current,
                        prefill: {
                            email: email
                        }
                    });
                }
            };

            script.onload = initCalendly;

            // If Calendly is already loaded, initialize immediately
            if (window.Calendly) {
                initCalendly();
            } else {
                document.body.appendChild(script);
            }

            return () => {
                if (script.parentNode) {
                    document.body.removeChild(script);
                }
            };
        }
    }, [showCalendly, email]);

    return (
        <>
            {!isOpen && (
                <button
                    onClick={() => setIsOpen(true)}
                    className={buttonClasses}
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                    </svg>
                </button>
            )}

            {isOpen && (
                <div className="fixed right-6 bottom-6 w-[32rem] max-h-[85vh] bg-white rounded-xl shadow-2xl border border-gray-200 flex flex-col">
                    <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-gradient-to-r from-blue-50 to-white rounded-t-xl">
                        <h3 className="text-lg font-semibold text-gray-800">LAMI - AI Transform Assistant</h3>
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={handleScheduleDemo}
                                className="text-blue-600 hover:text-blue-700 text-sm font-medium px-3 py-1 rounded-lg hover:bg-blue-50 transition-colors"
                            >
                                Schedule Demo
                            </button>
                            {messages.length > 0 && !isConfiguring && (
                                <button
                                    onClick={clearHistory}
                                    className="text-gray-500 hover:text-gray-700 transition-colors p-1.5 rounded-lg hover:bg-gray-100"
                                    title="Clear History"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </button>
                            )}
                            <button
                                onClick={handleClose}
                                className="text-gray-500 hover:text-gray-700 transition-colors p-1.5 rounded-lg hover:bg-gray-100"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    {!email ? (
                        <WelcomeScreen
                            onSubmit={handleEmailSubmit}
                            onContactUs={() => {
                                if (window.posthog) {
                                    window.posthog.capture('schedule_demo_clicked', {
                                        location: 'welcome_screen',
                                        email: 'not_provided'
                                    });
                                }
                                setShowCalendly(true);
                            }}
                        />
                    ) : (
                        <>
                            <div className="flex-1 overflow-y-auto p-4 space-y-4">
                                {messages.map((msg, idx) => (
                                    <div
                                        key={idx}
                                        className={`p-4 rounded-lg ${msg.role === 'assistant'
                                            ? 'bg-gradient-to-r from-blue-50 to-white border border-blue-100'
                                            : 'bg-gradient-to-r from-gray-50 to-white border border-gray-100'
                                            }`}
                                    >
                                        <MessageContent
                                            content={msg.content}
                                            onTransformationSuggested={onTransformationSuggested}
                                        />
                                    </div>
                                ))}
                                {isLoading && (
                                    <div className="p-4 rounded-lg bg-gradient-to-r from-blue-50 to-white border border-blue-100">
                                        <div className="flex items-center gap-2 text-sm text-gray-600">
                                            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
                                            AI is thinking...
                                        </div>
                                    </div>
                                )}
                                {usageCount >= 3 && !isLocalhost() && (
                                    <div className="p-4 rounded-lg bg-gradient-to-r from-yellow-50 to-white border border-yellow-100">
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm text-yellow-700">
                                                You're getting close to the usage limit. Schedule a demo for unlimited access!
                                            </p>
                                            <button
                                                onClick={handleScheduleDemo}
                                                className="text-yellow-600 hover:text-yellow-700 text-sm font-medium px-3 py-1 rounded-lg hover:bg-yellow-50 transition-colors"
                                            >
                                                Schedule Demo →
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div ref={messagesEndRef} />
                            </div>

                            <div className="border-t border-gray-200 bg-gray-50 p-4 rounded-b-xl">
                                <SuggestedPrompts
                                    onSelect={setInput}
                                    inputJson={inputJson}
                                    language={language}
                                    email={email}
                                    onTransformApplied={handleTransformationApplied}
                                    apiStatus={apiStatus}
                                    output={output}
                                    transformation={transformation}
                                />
                                <div className="flex space-x-2 mt-3">
                                    <input
                                        type="text"
                                        value={input}
                                        onChange={(e) => setInput(e.target.value)}
                                        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                                        placeholder="Ask about transformations..."
                                        className="flex-1 px-4 py-2.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        disabled={usageCount >= 5 && !isLocalhost() || isLoading}
                                    />
                                    <button
                                        onClick={handleSend}
                                        disabled={!input.trim() || isLoading}
                                        className={`px-6 py-2.5 rounded-lg font-medium transition-colors ${!input.trim() || isLoading
                                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                            : 'bg-blue-600 text-white hover:bg-blue-700'
                                            }`}
                                    >
                                        Send
                                    </button>
                                </div>
                                {!isLocalhost() && (
                                    <p className="mt-2 text-sm text-gray-500">
                                        {5 - usageCount} transformations remaining
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}

            {showCalendly && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg max-w-2xl w-full relative">
                        <button
                            onClick={() => {
                                if (window.posthog) {
                                    window.posthog.capture('calendly_modal_closed', {
                                        email: email || 'not_provided'
                                    });
                                }
                                setShowCalendly(false);
                            }}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 z-50"
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div
                            ref={calendlyRef}
                            className="calendly-inline-widget w-full"
                            style={{ minWidth: '320px', height: '700px' }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default AIChatPanel;