export const examples = {
    new: {
        name: "New Transform",
        js: `// Available imports:
// - lodash as _
// - date-fns: { format, parseISO }

(input) => {
    // Start with your transform here
    // Example: return only items where value > 5
    return _.chain(input)
            .get('data', [])  // safely get data array or default to empty
            .filter(item => item.value > 5)
            .value();
}`,
        jq: `# Start with your transform here
    # Example: select items where value > 5
    .data[]? | select(.value > 5)`,
        input: {
            "data": [
                { "value": 1 },
                { "value": 2 },
                { "value": 3 },
                { "value": 4 },
                { "value": 5 },
                { "value": 6 },
                { "value": 7 },
                { "value": 8 },
                { "value": 9 },
                { "value": 10 }
            ]
        }
    },
    simple: {
        name: "Simple Filter",
        js: `// Available imports:
// - lodash as _
// - date-fns: { format, parseISO }
  
(input) => {
    return _.chain(input.data)
      .filter(item => item.value > 5)
      .value();
}`,
        jq: `.data | map(select(.value > 5))`,
        input: {
            data: [
                { value: 3 },
                { value: 6 },
                { value: 8 },
                { value: 2 },
                { value: 9 }
            ]
        }
    },
    orders: {
        name: "Order Analytics",
        js: `// Available imports:
// - lodash as _
// - date-fns: { format, parseISO }

(input) => {
    const orderAnalytics = _.map(input.orders, order => ({
        id: order.id,
        customer: order.customer.name,
        total: _.sumBy(order.items, item => item.quantity * item.price),
        items: _.map(order.items, item => ({
            ...item,
            subtotal: item.quantity * item.price
        })),
        itemCount: order.items.length,
        date: format(parseISO(order.date), 'MMM dd, yyyy')
    }));

    return {
        orders: orderAnalytics,
        summary: {
            totalOrders: orderAnalytics.length,
            totalRevenue: _.sumBy(orderAnalytics, 'total'),
            averageOrderValue: _.meanBy(orderAnalytics, 'total'),
            customerStats: _(input.orders)
                .groupBy('customer.id')
                .map((orders, customerId) => ({
                    customerId,
                    customerName: orders[0].customer.name,
                    orderCount: orders.length,
                    totalSpent: _.sumBy(orders, order =>
                        _.sumBy(order.items, item => item.quantity * item.price)
                    )
                }))
                .value()
        }
    };
}`,
        jq: `# Calculate order totals and customer summary
{
  orders: [.orders[] | {
    id,
    customer: .customer.name,
    total: (.items | map(.quantity * .price) | add),
    item_count: (.items | length)
  }],
  summary: {
    total_orders: (.orders | length),
    total_revenue: (.orders | map(.items | map(.quantity * .price) | add) | add),
    unique_customers: (.orders | map(.customer.id) | unique | length)
  }
}`,
        input: {
            orders: [
                {
                    id: "ORD-001",
                    customer: {
                        id: "CUST-001",
                        name: "John Doe"
                    },
                    items: [
                        {
                            product: "Widget A",
                            quantity: 2,
                            price: 10.00
                        },
                        {
                            product: "Widget B",
                            quantity: 1,
                            price: 20.00
                        }
                    ],
                    date: "2024-01-15"
                },
                {
                    id: "ORD-002",
                    customer: {
                        id: "CUST-002",
                        name: "Jane Smith"
                    },
                    items: [
                        {
                            product: "Widget C",
                            quantity: 3,
                            price: 15.00
                        }
                    ],
                    date: "2024-01-16"
                }
            ]
        }
    },
    order_management: {
        input: {
            "orders": [
                {
                    "orderId": "ORD-001",
                    "customer": {
                        "id": "CUST-001",
                        "name": "John Smith",
                        "email": "john@example.com"
                    },
                    "items": [
                        {
                            "productId": "PROD-001",
                            "name": "Laptop",
                            "quantity": 1,
                            "price": 999.99
                        },
                        {
                            "productId": "PROD-002",
                            "name": "Mouse",
                            "quantity": 2,
                            "price": 24.99
                        }
                    ],
                    "shippingAddress": {
                        "street": "123 Main St",
                        "city": "Boston",
                        "state": "MA",
                        "zip": "02108"
                    },
                    "orderDate": "2024-03-15",
                    "status": "PROCESSING"
                }
            ]
        },
        jq: `# Transform orders into a more analytics-friendly format
.orders | map({
  order_id: .orderId,
  customer_id: .customer.id,
  customer_name: .customer.name,
  order_date: .orderDate,
  status: .status,
  total_items: (.items | length),
  total_amount: (.items | map(.quantity * .price) | add),
  shipping_location: "\(.shippingAddress.city), \(.shippingAddress.state)",
  items: .items | map({
    product_id: .productId,
    name,
    quantity,
    subtotal: (.quantity * .price)
  })
})`,
        js: `// Transform orders into a more analytics-friendly format
function transform(data) {
  return data.orders.map(order => ({
    order_id: order.orderId,
    customer_id: order.customer.id,
    customer_name: order.customer.name,
    order_date: order.orderDate,
    status: order.status,
    total_items: order.items.length,
    total_amount: order.items.reduce((sum, item) => sum + (item.quantity * item.price), 0),
    shipping_location: \`\${order.shippingAddress.city}, \${order.shippingAddress.state}\`,
    items: order.items.map(item => ({
      product_id: item.productId,
      name: item.name,
      quantity: item.quantity,
      subtotal: item.quantity * item.price
    }))
  }));
}`
    },
    simple: {
        input: {
            "users": [
                {
                    "id": 1,
                    "name": "John Doe",
                    "email": "john@example.com",
                    "age": 30
                },
                {
                    "id": 2,
                    "name": "Jane Smith",
                    "email": "jane@example.com",
                    "age": 25
                }
            ]
        },
        jq: `.users | map({
  id,
  full_name: .name,
  contact: .email,
  is_adult: .age >= 18
})`,
        js: `function transform(data) {
  return data.users.map(user => ({
    id: user.id,
    full_name: user.name,
    contact: user.email,
    is_adult: user.age >= 18
  }));
}`
    },
    error_prone: {
        name: "Error Prone Examples",
        input: {
            "data": [
                {
                    "id": 1,
                    "values": [10, 20, null, 40],
                    "metadata": null,
                    "date": "2024-03-15T12:00:00Z"
                },
                {
                    "id": 2,
                    "values": null,
                    "metadata": { "status": "active" },
                    "date": "invalid-date"
                },
                {
                    "id": 3,
                    "values": [30, "50", 60],
                    "metadata": { "status": "inactive" },
                    "date": "2024-03-16"
                }
            ]
        },
        js: `// This transform has several issues that AI can help fix:
// 1. Missing null checks
// 2. Type conversion issues
// 3. Date formatting errors
// 4. Array operation errors

(input) => {
    // Will throw errors due to missing null checks and type issues
    const result = input.data.map(item => ({
        id: item.id,
        sum: item.values.reduce((a, b) => a + b, 0),
        status: item.metadata.status,
        formattedDate: format(parseISO(item.date), 'yyyy-MM-dd')
    }));

    return result;
}`,
        jq: `# This transform has similar issues:
# 1. Missing null checks
# 2. Type conversion issues
# 3. Date handling issues

.data | map({
    id,
    sum: .values | add,
    status: .metadata.status,
    date: .date
})`
    },
    nested_errors: {
        name: "Nested Data Errors",
        input: {
            "orders": [
                {
                    "id": "ORD-001",
                    "customer": null,
                    "items": [
                        { "quantity": "5", "price": "10.99" },
                        { "quantity": null, "price": 20 }
                    ]
                },
                {
                    "id": "ORD-002",
                    "customer": { "name": "Jane Smith" },
                    "items": null
                },
                {
                    "id": "ORD-003",
                    "customer": { "name": "Bob Jones" },
                    "items": [
                        { "quantity": 2, "price": "invalid" },
                        { "quantity": "3", "price": 15 }
                    ]
                }
            ]
        },
        js: `// This transform has nested data issues:
// 1. Null customer reference
// 2. Missing items array
// 3. Type conversion in calculations
// 4. Invalid number values

(input) => {
    return input.orders.map(order => ({
        orderId: order.id,
        customerName: order.customer.name,
        total: order.items.reduce((sum, item) => 
            sum + (item.quantity * item.price), 0
        )
    }));
}`,
        jq: `.orders | map({
    order_id: .id,
    customer: .customer.name,
    total: (.items | map(.quantity * .price) | add)
})`
    },
    date_errors: {
        name: "Date Handling Errors",
        input: {
            "events": [
                {
                    "id": 1,
                    "timestamp": "2024-03-15T14:30:00Z",
                    "type": "login"
                },
                {
                    "id": 2,
                    "timestamp": null,
                    "type": "purchase"
                },
                {
                    "id": 3,
                    "timestamp": "invalid-date",
                    "type": "logout"
                },
                {
                    "id": 4,
                    "timestamp": "2024-03-15",  // missing time component
                    "type": "view"
                }
            ]
        },
        js: `// This transform has date handling issues:
// 1. Missing null checks
// 2. Invalid date strings
// 3. Inconsistent date formats
// 4. Timezone handling

(input) => {
    return input.events.map(event => ({
        id: event.id,
        type: event.type,
        date: format(parseISO(event.timestamp), 'MMM dd, yyyy HH:mm:ss'),
        isToday: isSameDay(parseISO(event.timestamp), new Date())
    }));
}`,
        jq: `.events | map({
    id,
    type,
    formatted_date: .timestamp,
    hour: (.timestamp | fromdateiso8601 | strftime("%H"))
})`
    }
};

export default examples;