import MonacoEditor from '@monaco-editor/react';
import { Calendar, Code, Database, Globe, MessageSquare, Play, Sparkles, Star, X } from 'lucide-react';
import * as monaco from 'monaco-editor';
import posthog from 'posthog-js';
import React, { useEffect, useState } from 'react';
import AIChatPanel from './AiChatPanel';
import { DataFlowIndicator } from './DataFlowIndicator';
import { examples } from "./examples";
import { lamLanguageDefinition, lamThemeDefinition } from './lam';
import NavBar from './NavBar';
import { PanelContainer } from './PanelContainer';
import { StepIndicator } from './StepIndicator';
import { WorkflowContextHeader } from './WorkflowContextHeader';

const LAMINAR_API_URL = "https://lamapi3.azurewebsites.net";
const TRANSFORM_ENDPOINT = "transform/test";

// Step-related constants
const titles = [
  "Input Data",
  "Transform",
  "Output",
  "Complete"
];

const descriptions = [
  "Review and prepare your input data",
  "Write or modify your transformation",
  "View and verify the output",
  "Transformation complete!"
];

const stepDescriptions = [
  "Review the input data and make any necessary adjustments",
  "Write or modify your data transformation",
  "Review the transformed output",
  "All done! Try modifying the transform or asking AI for help"
];

// Configure Monaco for JQ syntax highlighting
monaco.languages.register({ id: 'jq' });
monaco.languages.setMonarchTokensProvider('jq', {
  tokenizer: {
    root: [
      [/[{}]/, 'delimiter.curly'],
      [/[[\]]/, 'delimiter.square'],
      [/\.|,/, 'delimiter'],
      [/\b\d+\b/, 'number'],
      [/"([^"\\]|\\.)*"/, 'string'],
      [/\b(if|then|else|end|as|def|include|module|try|catch|label|break)\b/, 'keyword'],
      [/\b(true|false|null)\b/, 'constant'],
      [/#.*$/, 'comment'],
      [/\$[a-zA-Z_][a-zA-Z0-9_]*/, 'variable'],
      [/\|/, 'operator'],
    ]
  }
});

const updateSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

// Initialize PostHog
posthog.init('phc_wI70p9WSIUdjQ73nSokq1KCAg6ZsCoURemTM96KYyTF',
  {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
  }
)

function App() {
  const [language, setLanguage] = useState(window.sessionStorage.getItem("language") || "js");
  const [currentExample, setCurrentExample] = useState(window.sessionStorage.getItem("example") || "orders");
  const [inputJson, setInputJson] = useState(window.sessionStorage.getItem("inputJson") || JSON.stringify(examples.orders.input, null, 2));
  const [output, setOutput] = useState({});
  const [lamProgram, setLamProgram] = useState(window.sessionStorage.getItem(`${language}Program`) || examples.orders[language]);
  const [apiStatus, setApiStatus] = useState({ loading: false, error: null });
  const [activeTab, setActiveTab] = useState('output');
  const [showFeedback, setShowFeedback] = useState(false);

  // New state variables
  const [activePanel, setActivePanel] = useState(1);
  const [activeStep, setActiveStep] = useState(1);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [highlightAI, setHighlightAI] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [currentGuide, setCurrentGuide] = useState(0);
  const [aiContext, setAiContext] = useState(null);
  const [isAiChatOpen, setIsAiChatOpen] = useState(false);
  const [showWelcomeGuide, setShowWelcomeGuide] = useState(() => {
    return !localStorage.getItem('hasSeenWelcomeGuide');
  });
  const [welcomeStep, setWelcomeStep] = useState(0);

  // Add panel tour system
  const [activeTourPanel, setActiveTourPanel] = useState(null);
  const tourMessages = {
    1: {
      title: "Review Source Data",
      content: "This is the data from your source system. Take a moment to understand its structure - you'll be transforming this data in the next step.",
      icon: Globe,
      action: "Review the data structure, then click Next to proceed to writing your transform.",
      nextPanel: 2
    },
    2: {
      title: "Write Your Transform",
      content: "Now it's time to write your transformation. You can use JQ for simple filters or JavaScript for complex logic.",
      icon: Code,
      action: "1. Choose your language (JQ/JavaScript)\n2. Write or modify the transform\n3. Click 'Run Workflow' when ready",
      nextPanel: 3,
      highlightRun: true
    },
    3: {
      title: "Review Results",
      content: "Here's your transformed data. Review the output and optionally check the logs.",
      icon: Database,
      action: "1. Review the transformed output\n2. (Optional) Switch to logs tab to check execution details\n3. Click Complete when you're done",
      nextPanel: null,
      isLastStep: true
    }
  };

  // Add state for next step guidance
  const [showNextStepGuide, setShowNextStepGuide] = useState(false);
  const [hasCheckedLogs, setHasCheckedLogs] = useState(false);

  // Add state for AI nudge
  const [showAiNudge, setShowAiNudge] = useState(false);

  useEffect(() => {
    if (activeTab === 'log' && activePanel === 3) {
      setHasCheckedLogs(true);
    }
  }, [activeTab, activePanel]);

  const handleNextStep = () => {
    const currentMessage = tourMessages[activeTourPanel];
    if (!currentMessage) return;

    if (currentMessage.isLastStep) {
      // Complete the workflow
      setShowCelebration(true);
      setActiveTourPanel(null);
      setShowNextStepGuide(false);
      localStorage.setItem('hasCompletedTutorial', 'true');
    } else if (currentMessage.nextPanel) {
      handlePanelClick(currentMessage.nextPanel);
      setActiveTourPanel(currentMessage.nextPanel);
      setShowNextStepGuide(false);
    }
  };

  const handleDismissTour = () => {
    setActiveTourPanel(null);
    setShowNextStepGuide(true);
  };

  // Next step floating button
  const NextStepButton = () => {
    const nextPanel = tourMessages[activeTourPanel]?.nextPanel;
    const isLastStep = activeTourPanel === 3;

    if (!nextPanel && !isLastStep) return null;

    return (
      <div className="fixed bottom-4 right-4 flex items-center gap-4 bg-blue-600 text-white px-4 py-3 rounded-lg shadow-lg animate-fade-in">
        <div className="text-sm">
          {isLastStep ? (
            "Ready to complete the workflow!"
          ) : (
            <>
              <span className="font-medium">Next: </span>
              {activeTourPanel === 1 ? "Write your transform" :
                activeTourPanel === 2 ? "Run the workflow" :
                  "Review the output"}
            </>
          )}
        </div>
        <button
          onClick={handleNextStep}
          className="px-3 py-1.5 bg-white text-blue-600 rounded-md text-sm font-medium hover:bg-blue-50"
        >
          {isLastStep ? "Complete Workflow" : "Continue"}
        </button>
      </div>
    );
  };

  // Tour message overlay
  const TourMessage = ({ panel }) => (
    <div
      style={{
        position: 'absolute',
        left: '-320px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '300px',
        zIndex: 30
      }}
      className="bg-blue-50 p-4 rounded-lg border border-blue-200 shadow-sm animate-fade-in"
    >
      <div className="flex items-start gap-4">
        <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${panel === 1 ? 'bg-blue-100' :
          panel === 2 ? 'bg-green-100' :
            'bg-purple-100'
          }`}>
          {React.createElement(tourMessages[panel].icon, {
            className: `w-4 h-4 ${panel === 1 ? 'text-blue-600' :
              panel === 2 ? 'text-green-600' :
                'text-purple-600'
              }`
          })}
        </div>
        <div className="flex-1">
          <h4 className="font-medium text-gray-900 mb-1">{tourMessages[panel].title}</h4>
          <p className="text-sm text-gray-600 mb-3">{tourMessages[panel].content}</p>
          <div className="bg-white bg-opacity-50 rounded-lg p-3 space-y-2 text-sm">
            <div className="font-medium text-gray-900">What to do:</div>
            {tourMessages[panel].action.split('\n').map((step, i) => (
              <div key={i} className="flex items-start gap-2">
                <div className="text-blue-600">•</div>
                <div>{step}</div>
              </div>
            ))}
          </div>
          <button
            onClick={handleNextStep}
            className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium"
          >
            {tourMessages[panel].isLastStep ? 'Complete' : 'Next Step'}
          </button>
        </div>
        <button
          onClick={handleDismissTour}
          className="text-gray-400 hover:text-gray-500"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
      <div className="absolute top-1/2 right-0 w-3 h-3 bg-blue-50 border-t border-r border-blue-200 transform translate-x-1.5 rotate-45 -translate-y-1.5"></div>
    </div>
  );

  // Update PanelOverlay to only cover the editor area
  const PanelOverlay = ({ isActive, children }) => {
    const isTutorialActive = activeTourPanel !== null;
    const shouldGrayOut = isTutorialActive && !isActive;

    return (
      <div className="relative">
        {shouldGrayOut && (
          <div className="absolute top-[64px] inset-x-0 bottom-0 bg-gray-500 bg-opacity-50 z-20 pointer-events-none" />
        )}
        {children}
      </div>
    );
  };

  // Success celebration overlay
  const [showCelebration, setShowCelebration] = useState(false);

  const guides = [
    {
      title: "Welcome to Laminar Playground!",
      content: "Let's transform your data with ease. First, select an example to get started.",
      target: 1,
      highlight: "example"
    },
    {
      title: "Review Input Data",
      content: "Here's your source data. Take a moment to understand its structure.",
      target: 1,
      highlight: "input"
    },
    {
      title: "Choose Your Language",
      content: "Select JQ for powerful data querying or JavaScript for complex transformations.",
      target: 2,
      highlight: "language"
    },
    {
      title: "Write Your Transform",
      content: "Now write or modify the transformation code. Need help? Ask our AI assistant!",
      target: 2,
      highlight: "transform"
    }
  ];

  const welcomeSteps = [
    {
      title: "Welcome to Laminar",
      content: (
        <div className="space-y-6">
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">Welcome to Laminar</h2>
            <p className="text-gray-600 mb-4">The low-code platform for building complex bespoke integrations</p>
          </div>

          <div className="bg-blue-50 p-4 rounded-lg">
            <h3 className="font-medium text-blue-900 mb-2">Let's Build an Integration</h3>
            <p className="text-sm text-gray-600">We'll walk through creating a data transformation - a key part of any integration workflow. We'll start with a simple example to learn the basics.</p>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="p-4 bg-white rounded-lg border border-gray-200">
              <div className="w-8 h-8 bg-blue-100 rounded-lg flex items-center justify-center mb-2">
                <Globe className="w-5 h-5 text-blue-600" />
              </div>
              <h4 className="font-medium">1. Source</h4>
              <p className="text-sm text-gray-600">External API data</p>
            </div>
            <div className="p-4 bg-white rounded-lg border border-gray-200">
              <div className="w-8 h-8 bg-green-100 rounded-lg flex items-center justify-center mb-2">
                <Code className="w-5 h-5 text-green-600" />
              </div>
              <h4 className="font-medium">2. Transform</h4>
              <p className="text-sm text-gray-600">Process the data</p>
            </div>
            <div className="p-4 bg-white rounded-lg border border-gray-200">
              <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center mb-2">
                <Database className="w-5 h-5 text-purple-600" />
              </div>
              <h4 className="font-medium">3. Destination</h4>
              <p className="text-sm text-gray-600">Analytics ready</p>
            </div>
          </div>
        </div>
      )
    }
  ];

  useEffect(() => {
    // Register LAM language
    monaco.languages.register({ id: 'lam' });
    monaco.languages.setMonarchTokensProvider('lam', lamLanguageDefinition);

    // Register LAM theme
    monaco.editor.defineTheme('lamTheme', lamThemeDefinition);

    // Define custom theme for other languages
    monaco.editor.defineTheme('custom-theme', {
      base: 'vs',
      inherit: true,
      rules: [
        { token: 'keyword', foreground: '0000FF', fontStyle: 'bold' },
        { token: 'string', foreground: 'A31515' },
        { token: 'number', foreground: '098658' },
        { token: 'delimiter.curly', foreground: '000000' },
        { token: 'delimiter.square', foreground: '000000' },
        { token: 'delimiter', foreground: '000000' },
        { token: 'operator', foreground: '000000' }
      ],
      colors: {
        'editor.foreground': '#000000',
        'editor.background': '#FFFFFF',
        'editor.lineHighlightBackground': '#F7F7F7',
        'editorCursor.foreground': '#000000',
        'editor.selectionBackground': '#ADD6FF'
      }
    });

    // Listen for AI chat open event
    const handleOpenAiChat = () => setIsAiChatOpen(true);
    window.addEventListener('openAiChat', handleOpenAiChat);

    // Listen for welcome guide event
    const handleShowWelcome = () => setShowWelcomeGuide(true);
    window.addEventListener('showWelcomeDialog', handleShowWelcome);

    return () => {
      window.removeEventListener('openAiChat', handleOpenAiChat);
      window.removeEventListener('showWelcomeDialog', handleShowWelcome);
    };
  }, []);

  // Track important events
  const trackEvent = (eventName, properties = {}) => {
    posthog.capture(eventName, {
      ...properties,
      language,
      currentExample,
      hasCompletedTutorial: localStorage.getItem('hasCompletedTutorial') === 'true'
    });
  };

  // Update handlers to include tracking
  const handleExampleChange = (example) => {
    trackEvent('example_changed', { example });
    updateSessionStorage("example", example);
    setCurrentExample(example);
    setInputJson(JSON.stringify(examples[example].input, null, 2));
    setLamProgram(examples[example][language]);
    updateSessionStorage("inputJson", JSON.stringify(examples[example].input, null, 2));
    updateSessionStorage(`${language}Program`, examples[example][language]);
  };

  const handleLanguageChange = (newLanguage) => {
    trackEvent('language_changed', { newLanguage });
    updateSessionStorage("language", newLanguage);
    updateSessionStorage(`${language}Program`, lamProgram);
    const savedProgram = window.sessionStorage.getItem(`${newLanguage}Program`) || examples[currentExample][newLanguage];
    setLamProgram(savedProgram);
    setLanguage(newLanguage);
  };

  const handleLamProgramChange = (newValue) => {
    updateSessionStorage(`${language}Program`, newValue);
    setLamProgram(newValue);
  };

  const handleInputChange = (newValue) => {
    updateSessionStorage("inputJson", newValue);
    setInputJson(newValue);
  };

  const handleTransformationSuggested = (transformation) => {
    setLamProgram(transformation);
    updateSessionStorage(`${language}Program`, transformation);
  };

  const handleRunSuccess = () => {
    trackEvent('workflow_completed');
    // Only show success and clear tour if we've completed all steps
    if (activeStep >= 3 && !localStorage.getItem('hasCompletedTutorial')) {
      setShowSuccess(true);
      localStorage.setItem('hasCompletedTutorial', 'true');
      // Only clear tour panel if we've completed the tutorial
      setActiveTourPanel(null);
    }
    setActiveStep(4); // Mark all steps as complete
    setActivePanel(3);
  };

  const handleRun = async () => {
    trackEvent('workflow_run_started');
    setApiStatus({ loading: true, error: null });

    // If we're in panel 2 and the tutorial is active, treat this as a next step click
    if (activePanel === 2 && activeTourPanel === 2 && !localStorage.getItem('hasCompletedTutorial')) {
      handleNextStep();
    }

    setActiveStep(3);
    setActivePanel(3);
    // Set tour panel to 3 if we haven't completed tutorial yet
    if (!localStorage.getItem('hasCompletedTutorial')) {
      setActiveTourPanel(3);
    }
    setShowNextStepGuide(false);

    try {
      const stringifiedInputJson = JSON.stringify(JSON.parse(inputJson));
      const response = await fetch(`${LAMINAR_API_URL}/${TRANSFORM_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          program: lamProgram,
          input: stringifiedInputJson,
          language: language
        })
      });

      const json = await response.json();

      if (json.error) {
        trackEvent('workflow_run_error', { error: json.error });
        setApiStatus({ loading: false, error: json.error });
        return;
      }

      trackEvent('workflow_run_success');
      setOutput({
        result: json.result,
        log: Array.isArray(json.log) ? json.log.join('\n') : json.log
      });
      setApiStatus({ loading: false, error: null });
      handleRunSuccess();
    } catch (error) {
      trackEvent('workflow_run_error', { error: error.message });
      console.error("Error: ", error);
      setApiStatus({ loading: false, error: error.message });
    }
  };

  const handleExport = () => {
    const template = `// ⚠️ WARNING: This is an example implementation. DO NOT use in production!
// For production usage, please use Laminar's offical platform at https://app.laminar.run

const program = \`${lamProgram}\`;
const input = \`${inputJson}\`;

async function runTransform() {
  try {
    const response = await fetch('https://api.laminar.run/transform/test', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        program: program,
        input: input,
        language: '${language}'
      })
    });

    const result = await response.json();
    console.log('Transform Result:', result);
    return result;
  } catch (error) {
    console.error('Transform Error:', error);
    throw error;
  }
}

runTransform();`;

    const blob = new Blob([template], { type: 'text/javascript' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'laminar-transform.js';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const editorOptions = {
    minimap: { enabled: false },
    scrollBeyondLastLine: false,
    wordWrap: 'on',
    automaticLayout: true,
    fontSize: 14,
    formatOnPaste: true,
    formatOnType: true,
    bracketPairColorization: { enabled: true },
    guides: {
      bracketPairs: true,
      indentation: true
    }
  };

  // New handlers
  const handlePanelClick = (panelNumber) => {
    // Only allow clicking panels that are next in sequence
    if (panelNumber <= activeStep + 1) {
      setActivePanel(panelNumber);
      setHasInteracted(true);
      if (activeStep < panelNumber) {
        setActiveStep(panelNumber);
      }
      // Only show tour panel if tutorial hasn't been completed
      if (!localStorage.getItem('hasCompletedTutorial')) {
        setActiveTourPanel(panelNumber);
      }
    }
  };

  const handleHelpClick = (panelNumber) => {
    setActiveTourPanel(panelNumber);
  };

  const handleGuideNext = () => {
    if (currentGuide < guides.length - 1) {
      setCurrentGuide(prev => prev + 1);
      setActivePanel(guides[currentGuide + 1].target);
    } else {
      setShowGuide(false);
    }
  };

  const handleGuideSkip = () => {
    setShowGuide(false);
  };

  const onAiHelp = (context) => {
    setAiContext(context);
    setIsAiChatOpen(true);
  };

  const handleWelcomeNext = () => {
    if (welcomeStep < welcomeSteps.length - 1) {
      setWelcomeStep(prev => prev + 1);
    } else {
      handleWelcomeClose();
    }
  };

  const handleWelcomeClose = () => {
    setShowWelcomeGuide(false);
    localStorage.setItem('hasSeenWelcomeGuide', 'true');
    // Start with order analytics example
    handleExampleChange('orders');
    localStorage.setItem('hasSelectedExample', 'true');
    // Start the tour with the first panel
    setActiveStep(1);
    setActivePanel(1);
    setActiveTourPanel(1);
  };

  // Update the guide content to show above panels instead of modals
  const GuideOverlay = ({ currentGuide }) => (
    <div className="absolute -top-16 left-0 right-0 bg-blue-50 p-4 rounded-lg border border-blue-200 shadow-sm z-10">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="font-medium text-blue-900">{guides[currentGuide].title}</h3>
          <p className="text-sm text-blue-700">{guides[currentGuide].content}</p>
        </div>
        <button
          onClick={() => setShowGuide(false)}
          className="text-blue-600 hover:text-blue-700"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
    </div>
  );

  // Feedback and Demo Modal
  const FeedbackModal = ({ onClose }) => {
    const [rating, setRating] = useState(0);
    const [email, setEmail] = useState('');
    const [showBookDemo, setShowBookDemo] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      trackEvent('feedback_submitted', { rating });

      if (email) {
        trackEvent('demo_requested', { email });
        // Here you can integrate with your actual booking system
        // For now, we'll just show a success message
        setShowBookDemo(true);
      }
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-8 shadow-2xl max-w-lg w-full">
          {!showBookDemo ? (
            <div className="space-y-6">
              <div className="text-center">
                <h2 className="text-2xl font-semibold text-gray-900 mb-2">How was your experience?</h2>
                <p className="text-gray-600">We'd love to hear your thoughts and show you what else Laminar can do!</p>
              </div>

              <div className="flex justify-center gap-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => setRating(star)}
                    className={`p-2 rounded-full transition-colors ${rating >= star ? 'text-yellow-400 hover:text-yellow-500' : 'text-gray-300 hover:text-gray-400'}`}
                  >
                    <Star className="w-8 h-8 fill-current" />
                  </button>
                ))}
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Want to see more? Book a demo!
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-gray-600 hover:text-gray-700"
                  >
                    Skip
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  >
                    {email ? 'Book Demo' : 'Submit Rating'}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="text-center space-y-6">
              <div className="w-16 h-16 bg-green-100 rounded-full mx-auto flex items-center justify-center">
                <Calendar className="w-8 h-8 text-green-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Thanks for your interest!</h3>
                <p className="text-gray-600">We'll be in touch shortly to schedule your demo.</p>
              </div>
              <button
                onClick={onClose}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Got it!
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Add animation class for next action
  const getNextActionClass = (panelNumber) => {
    if (activeTourPanel === null && activeStep === panelNumber - 1) {
      return 'animate-bounce-gentle';
    }
    return '';
  };

  // Add AI nudge component
  const AiNudge = () => (
    <div className="fixed bottom-4 right-4 bg-blue-600 text-white p-4 rounded-lg shadow-lg max-w-md animate-fade-in">
      <div className="flex items-start gap-4">
        <div className="bg-blue-500 rounded-lg p-2">
          <MessageSquare className="w-6 h-6" />
        </div>
        <div>
          <h4 className="font-medium mb-1">Try AI Assistance</h4>
          <p className="text-sm text-blue-100 mb-3">
            Ask our AI to help you transform data, optimize your code, or explain the results.
          </p>
          <div className="flex gap-3">
            <button
              onClick={() => {
                setIsAiChatOpen(true);
                setShowAiNudge(false);
                trackEvent('ai_nudge_accepted');
              }}
              className="px-3 py-1.5 bg-white text-blue-600 rounded-md text-sm font-medium hover:bg-blue-50"
            >
              Try it now
            </button>
            <button
              onClick={() => {
                setShowAiNudge(false);
                trackEvent('ai_nudge_dismissed');
              }}
              className="px-3 py-1.5 text-blue-200 hover:text-white text-sm"
            >
              Maybe later
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Update CelebrationModal with workflow diagram
  const CelebrationModal = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-8 shadow-2xl max-w-2xl w-full text-center space-y-6">
        <div className="w-20 h-20 bg-green-100 rounded-full mx-auto flex items-center justify-center mb-4">
          <Sparkles className="w-10 h-10 text-green-600 animate-bounce" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900">Workflow Ready to Deploy!</h2>
        <p className="text-gray-600">Great job! You've successfully created and tested your workflow.</p>

        {/* Workflow Diagram */}
        <div className="bg-gray-50 p-6 rounded-lg">
          <div className="flex items-center justify-between gap-4 max-w-xl mx-auto">
            <div className="flex flex-col items-center gap-2">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
                <Globe className="w-6 h-6 text-blue-600" />
              </div>
              <div className="text-sm font-medium text-gray-900">Source System</div>
              <div className="text-xs text-gray-500">External API</div>
            </div>

            <div className="flex-1 relative">
              <div className="absolute inset-0 flex items-center">
                <div className="h-1 w-full bg-blue-200 relative">
                  <div className="absolute inset-0 bg-blue-600 animate-flow"></div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center gap-2">
              <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center">
                <Code className="w-6 h-6 text-green-600" />
              </div>
              <div className="text-sm font-medium text-gray-900">Laminar</div>
              <div className="text-xs text-gray-500">Transform Engine</div>
            </div>

            <div className="flex-1 relative">
              <div className="absolute inset-0 flex items-center">
                <div className="h-1 w-full bg-purple-200 relative">
                  <div className="absolute inset-0 bg-purple-600 animate-flow"></div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center gap-2">
              <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center">
                <Database className="w-6 h-6 text-purple-600" />
              </div>
              <div className="text-sm font-medium text-gray-900">Destination</div>
              <div className="text-xs text-gray-500">Your System</div>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <p className="text-gray-600">You'd normally deploy this workflow in your application using the following API call:</p>
          <code className="block bg-gray-50 p-4 rounded-lg text-sm font-mono text-gray-600 overflow-x-auto">
            POST https://api.laminar.run/workflows/execute/external/:id?api_key=:api_key
          </code>
        </div>

        <button
          onClick={() => {
            onClose();
            setShowFeedback(true);
            // Show AI nudge after a delay
            setTimeout(() => setShowAiNudge(true), 2000);
          }}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mt-4"
        >
          Continue
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 pb-20">
      <NavBar />

      {/* Welcome Guide - Show First */}
      {showWelcomeGuide && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl max-w-2xl w-full p-6 shadow-2xl">
            <div className="text-right">
              <button
                onClick={handleWelcomeClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-6">
              {welcomeSteps[welcomeStep].content}

              <div className="flex justify-between items-center">
                <button
                  onClick={handleWelcomeNext}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  {welcomeStep === welcomeSteps.length - 1 ? "Get Started" : "Next"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Sticky Header */}
      <div className="sticky top-0 z-10 bg-gray-100">
        <WorkflowContextHeader
          currentExample={currentExample}
          onExampleChange={handleExampleChange}
          language={language}
          onLanguageChange={handleLanguageChange}
        />
      </div>

      {/* Main Content */}
      <div className="p-4 space-y-4">
        {/* Steps and Panels */}
        <div className="grid grid-cols-4 gap-4">
          {/* Left Side: Steps */}
          <div className="space-y-2">
            {[1, 2, 3, 4].map(step => (
              <StepIndicator
                key={step}
                step={step}
                isActive={activeStep === step}
                isComplete={activeStep > step}
                onClick={() => {
                  handlePanelClick(step);
                }}
              />
            ))}
          </div>

          {/* Right Side: Content Area */}
          <div className="col-span-3 space-y-4">
            {/* Run Controls - Moved to top */}
            <div className="bg-white p-4 rounded-lg shadow-sm flex justify-between items-center">
              <div className="text-sm text-gray-500">
                {!hasInteracted ?
                  "Start by selecting an example and reviewing the input data" :
                  activeStep === 4 ?
                    "Transform complete! Try modifying the transform or asking AI for help" :
                    `Step ${activeStep}: ${stepDescriptions[activeStep - 1]}`
                }
              </div>
              <button
                onClick={handleRun}
                className={`
                  inline-flex items-center px-6 py-2 rounded-md text-sm 
                  font-medium transition-colors
                  ${apiStatus.loading ? 'bg-blue-400 cursor-not-allowed' :
                    activeStep === 4 && !output?.result ? 'bg-blue-600 hover:bg-blue-700 animate-bounce' :
                      'bg-blue-600 hover:bg-blue-700'
                  } text-white shadow-sm
                `}
                disabled={apiStatus.loading}
              >
                {apiStatus.loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    <Play className="w-4 h-4 mr-2" />
                    Run Workflow
                  </>
                )}
              </button>
            </div>

            {/* Panels with Flow Indicator */}
            <div className="relative grid grid-cols-3 gap-4">
              {activeTourPanel && <TourMessage panel={activeTourPanel} />}
              <DataFlowIndicator
                isRunning={apiStatus.loading}
                isSuccess={showSuccess}
              />

              <PanelOverlay isActive={activePanel === 1}>
                <PanelContainer
                  icon={Globe}
                  title="Input Data"
                  description="From source system"
                  isActive={activePanel === 1}
                  onClick={() => handlePanelClick(1)}
                  onHelpClick={handleHelpClick}
                  panelNumber={1}
                  className={getNextActionClass(1)}
                  aiSuggestion={null}
                >
                  <div className="h-[calc(100vh-300px)]">
                    <MonacoEditor
                      height="100%"
                      language="json"
                      theme="custom-theme"
                      value={inputJson}
                      onChange={handleInputChange}
                      options={editorOptions}
                    />
                  </div>
                </PanelContainer>
              </PanelOverlay>

              <PanelOverlay isActive={activePanel === 2}>
                <PanelContainer
                  icon={Code}
                  title="Transform"
                  description="Process the data"
                  isActive={activePanel === 2}
                  onClick={() => handlePanelClick(2)}
                  onHelpClick={handleHelpClick}
                  panelNumber={2}
                  className={getNextActionClass(2)}
                  aiSuggestion={null}
                >
                  <div className="flex items-center justify-between gap-4 px-4 py-2 bg-gray-50 border-b border-gray-200">
                    <div className="flex gap-2 bg-white p-1 rounded-lg shadow-sm">
                      <button
                        onClick={() => handleLanguageChange('js')}
                        className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${language === 'js'
                          ? 'bg-blue-50 text-blue-600 shadow-sm'
                          : 'text-gray-600 hover:bg-gray-50'
                          }`}
                      >
                        JavaScript
                      </button>
                      <button
                        onClick={() => handleLanguageChange('jq')}
                        className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${language === 'jq'
                          ? 'bg-blue-50 text-blue-600 shadow-sm'
                          : 'text-gray-600 hover:bg-gray-50'
                          }`}
                        title="JQ is more performant but JavaScript is easier to read"
                      >
                        JQ
                      </button>
                    </div>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={handleExport}
                        className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 shadow-sm"
                      >
                        <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                        Export
                      </button>
                      <button
                        onClick={handleRun}
                        className={`
                          inline-flex items-center px-4 py-2 rounded-md text-sm 
                          font-medium transition-colors
                          ${apiStatus.loading ? 'bg-blue-400 cursor-not-allowed' :
                            'bg-blue-600 hover:bg-blue-700'
                          } text-white shadow-sm
                          ${activePanel === 2 && !output?.result ? 'animate-bounce' : ''}
                        `}
                        disabled={apiStatus.loading}
                      >
                        {apiStatus.loading ? (
                          <>
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                            </svg>
                            Processing...
                          </>
                        ) : (
                          <>
                            <Play className="w-4 h-4 mr-2" />
                            Run Workflow
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="h-[calc(100vh-350px)]">
                    <MonacoEditor
                      height="100%"
                      language={language === 'js' ? 'javascript' : language === 'jq' ? 'jq' : 'lam'}
                      theme={language === 'jq' ? 'lamTheme' : 'custom-theme'}
                      value={lamProgram}
                      onChange={handleLamProgramChange}
                      options={editorOptions}
                    />
                  </div>
                </PanelContainer>
              </PanelOverlay>

              <PanelOverlay isActive={activePanel === 3}>
                <PanelContainer
                  icon={Database}
                  title="Output"
                  description="Transformed data"
                  isActive={activePanel === 3}
                  onClick={() => handlePanelClick(3)}
                  onHelpClick={handleHelpClick}
                  panelNumber={3}
                  className={getNextActionClass(3)}
                  aiSuggestion={null}
                >
                  <div className="flex border-b border-gray-200">
                    <button
                      className={`flex-1 text-sm font-medium px-4 py-3 ${activeTab === 'output'
                        ? 'text-blue-600 border-b-2 border-blue-500 bg-white'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                        }`}
                      onClick={() => setActiveTab('output')}
                    >
                      Output
                    </button>
                    <button
                      className={`flex-1 text-sm font-medium px-4 py-3 ${activeTab === 'log'
                        ? 'text-blue-600 border-b-2 border-blue-500 bg-white'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                        }`}
                      onClick={() => setActiveTab('log')}
                    >
                      Logs {output?.log ? '•' : ''}
                    </button>
                  </div>
                  <div className="h-[calc(100vh-350px)]">
                    {activeTab === 'output' ? (
                      <MonacoEditor
                        height="100%"
                        language="json"
                        theme="custom-theme"
                        value={output?.result ? JSON.stringify(output.result, null, 2) :
                          JSON.stringify({
                            message: "Transform output will appear here after you click Run",
                            tip: "Try modifying the transform and input, then click Run to see the results"
                          }, null, 2)}
                        options={{
                          ...editorOptions,
                          readOnly: true
                        }}
                      />
                    ) : (
                      <div className="h-full bg-gray-50 p-4 overflow-auto font-mono">
                        {output?.log ? (
                          <div className="space-y-1">
                            {output.log.split('\n').map((line, index) => (
                              <div
                                key={index}
                                className={`py-1 px-2 rounded text-sm ${line.includes('ERROR') ? 'bg-red-50 text-red-700' :
                                  line.includes('WARN') ? 'bg-yellow-50 text-yellow-700' :
                                    line.includes('INFO') ? 'bg-blue-50 text-blue-700' :
                                      'text-gray-600'
                                  }`}
                              >
                                {line}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-gray-500 italic text-sm">No logs available.</div>
                        )}
                      </div>
                    )}
                  </div>
                </PanelContainer>
              </PanelOverlay>

              {/* Add flow animation between panels */}
              <div className="absolute inset-0 pointer-events-none">
                <svg className="w-full h-full">
                  <defs>
                    <linearGradient id="flow-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.1" />
                      <stop offset="50%" stopColor="#3B82F6" stopOpacity="0.3" />
                      <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.1" />
                    </linearGradient>
                  </defs>
                  {apiStatus.loading && (
                    <>
                      <path
                        d="M200 50 L400 50"
                        stroke="url(#flow-gradient)"
                        strokeWidth="4"
                        fill="none"
                        className="animate-flow"
                      />
                      <path
                        d="M600 50 L800 50"
                        stroke="url(#flow-gradient)"
                        strokeWidth="4"
                        fill="none"
                        className="animate-flow"
                      />
                    </>
                  )}
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Enhanced success celebration */}
      {showCelebration && <CelebrationModal onClose={() => setShowCelebration(false)} />}

      {/* Feedback modal */}
      {showFeedback && <FeedbackModal onClose={() => setShowFeedback(false)} />}

      {/* AI nudge */}
      {showAiNudge && <AiNudge />}

      <AIChatPanel
        isOpen={isAiChatOpen}
        onClose={() => setIsAiChatOpen(false)}
        language={language}
        inputJson={inputJson}
        onTransformationSuggested={handleTransformationSuggested}
        output={output}
        apiStatus={apiStatus}
        transformation={lamProgram}
        aiContext={aiContext}
      />

      {/* Next step button */}
      {showNextStepGuide && <NextStepButton />}

      {/* Add global AI help button */}
      <button
        onClick={() => setIsAiChatOpen(true)}
        className="fixed right-4 top-20 bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700 flex items-center gap-2"
      >
        <MessageSquare className="w-5 h-5" />
        Ask AI for Help
      </button>
    </div>
  );
}

export default App;