import {
    ArrowRight,
    Bot,
    Code,
    Database,
    Globe, HelpCircle,
    X
} from 'lucide-react';
import React, { useEffect, useState } from 'react';

import {
    Navigation,
    Sparkles,
    Target
} from 'lucide-react';

const WelcomeDialog = ({ isOpen, onClose, currentExample, onExampleSelect }) => {
    const [currentStep, setCurrentStep] = useState(0);

    if (!isOpen) return null;

    const steps = [
        {
            title: "Welcome to Your Data Transform Journey!",
            content: (
                <div className="space-y-4">
                    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-6">
                        <div className="flex items-center gap-3 mb-4">
                            <div className="w-12 h-12 rounded-lg bg-blue-100 flex items-center justify-center">
                                <Navigation className="w-6 h-6 text-blue-600" />
                            </div>
                            <div>
                                <h3 className="font-medium text-blue-900">You Are Here</h3>
                                <p className="text-sm text-blue-700">Transform Task in Step 1</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between text-sm text-blue-800 mt-4">
                            <div className="flex items-center gap-2">
                                <Target className="w-4 h-4" />
                                <span>Mission: Transform incoming data for the next step</span>
                            </div>
                        </div>
                    </div>

                    <div className="bg-gray-50 rounded-lg p-4">
                        <h3 className="font-medium text-gray-900 mb-3">Your Tools</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center gap-2 bg-white p-3 rounded border border-gray-200">
                                <Code className="w-5 h-5 text-blue-500" />
                                <div className="text-sm">
                                    <div className="font-medium">Code Editor</div>
                                    <div className="text-gray-500">JQ or JavaScript</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 bg-white p-3 rounded border border-gray-200">
                                <Bot className="w-5 h-5 text-purple-500" />
                                <div className="text-sm">
                                    <div className="font-medium">AI Assistant</div>
                                    <div className="text-gray-500">Your coding buddy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: "Let's Start With an Example",
            content: (
                <div className="space-y-4">
                    <div className="bg-gradient-to-r from-green-50 to-blue-50 rounded-lg p-6">
                        <div className="flex items-center gap-3 mb-4">
                            <div className="w-12 h-12 rounded-lg bg-green-100 flex items-center justify-center">
                                <Sparkles className="w-6 h-6 text-green-600" />
                            </div>
                            <div>
                                <h3 className="font-medium text-green-900">Choose Your First Challenge</h3>
                                <p className="text-sm text-green-700">Select a practical example to get started</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <button
                                onClick={() => onExampleSelect('simple')}
                                className="flex flex-col p-4 rounded-lg border-2 border-transparent hover:border-green-200 bg-white"
                            >
                                <div className="font-medium text-gray-900">Simple Filter</div>
                                <div className="text-sm text-gray-500">Perfect for beginners</div>
                            </button>
                            <button
                                onClick={() => onExampleSelect('orders')}
                                className="flex flex-col p-4 rounded-lg border-2 border-transparent hover:border-green-200 bg-white"
                            >
                                <div className="font-medium text-gray-900">Order Analytics</div>
                                <div className="text-sm text-gray-500">Real-world scenario</div>
                            </button>
                        </div>
                    </div>

                    <div className="bg-yellow-50 rounded-lg p-4 text-sm text-yellow-800">
                        <p>💡 After selecting an example, try these steps:</p>
                        <ol className="list-decimal ml-5 mt-2 space-y-1">
                            <li>Review the input data</li>
                            <li>Modify the transformation</li>
                            <li>Ask the AI for help!</li>
                        </ol>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl max-w-2xl w-full shadow-2xl">
                <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                        <h2 className="text-xl font-semibold text-gray-900">{steps[currentStep].title}</h2>
                        <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                            <X className="w-5 h-5" />
                        </button>
                    </div>

                    {steps[currentStep].content}

                    <div className="mt-6 flex justify-between items-center">
                        <div className="flex gap-1">
                            {steps.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={`w-2 h-2 rounded-full ${idx === currentStep ? 'bg-blue-600' : 'bg-gray-300'}`}
                                />
                            ))}
                        </div>
                        <div className="flex gap-3">
                            {currentStep > 0 && (
                                <button
                                    onClick={() => setCurrentStep(prev => prev - 1)}
                                    className="px-4 py-2 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50"
                                >
                                    Back
                                </button>
                            )}
                            {currentStep < steps.length - 1 ? (
                                <button
                                    onClick={() => setCurrentStep(prev => prev + 1)}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                                >
                                    Next
                                </button>
                            ) : (
                                <button
                                    onClick={onClose}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                                >
                                    Let's Begin!
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ExampleContext = ({ example }) => {
    // Map example names to their workflow contexts
    const exampleContexts = {
        'simple': {
            source: 'External API',
            sourceIcon: Globe,
            destination: 'Analytics Database',
            destIcon: Database,
            description: 'Filter and clean incoming API data before storage'
        },
        'orders': {
            source: 'Order Management System',
            sourceIcon: Globe,
            destination: 'Analytics Dashboard',
            destIcon: Database,
            description: 'Transform raw order data into analytics-ready metrics'
        },
        'new': {
            source: 'Data Source',
            sourceIcon: Database,
            destination: 'Destination',
            destIcon: Database,
            description: 'Create a new data transformation'
        }
    };

    const context = exampleContexts[example] || exampleContexts.new;
    const SourceIcon = context.sourceIcon;
    const DestIcon = context.destIcon;

    return (
        <div className="flex items-center gap-8 w-full max-w-2xl">
            <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-lg bg-green-100 flex items-center justify-center">
                    <SourceIcon className="w-5 h-5 text-green-600" />
                </div>
                <div>
                    <div className="text-sm font-medium text-gray-900">{context.source}</div>
                    <div className="text-xs text-gray-500">Data Source</div>
                </div>
            </div>

            <ArrowRight className="w-5 h-5 text-gray-400 flex-shrink-0" />

            <div className="flex flex-col items-center flex-shrink-0">
                <div className="w-10 h-10 rounded-lg bg-blue-100 flex items-center justify-center">
                    <Code className="w-5 h-5 text-blue-600" />
                </div>
                <span className="text-xs font-medium text-blue-600 mt-1">Transform</span>
            </div>

            <ArrowRight className="w-5 h-5 text-gray-400 flex-shrink-0" />

            <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-lg bg-purple-100 flex items-center justify-center">
                    <DestIcon className="w-5 h-5 text-purple-600" />
                </div>
                <div>
                    <div className="text-sm font-medium text-gray-900">{context.destination}</div>
                    <div className="text-xs text-gray-500">Destination</div>
                </div>
            </div>
        </div>
    );
};

export const WorkflowContextHeader = ({ currentExample, onExampleChange }) => {
    return (
        <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                        <select
                            value={currentExample}
                            onChange={(e) => onExampleChange(e.target.value)}
                            className="form-select rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        >
                            <option value="new">New Transform</option>
                            <option value="simple">Simple Filter</option>
                            <option value="orders">Order Analytics</option>
                        </select>
                        <button
                            onClick={() => window.dispatchEvent(new CustomEvent('showWelcomeDialog'))}
                            className="text-gray-500 hover:text-gray-700 p-1.5 rounded-lg hover:bg-gray-50"
                            title="Show Guide"
                        >
                            <HelpCircle className="w-5 h-5" />
                        </button>
                    </div>
                    <div className="h-6 w-px bg-gray-200" />
                    <ExampleContext example={currentExample} />
                </div>
                <div className="flex items-center gap-3">
                    <div className="text-sm text-gray-500">Need help?</div>
                    <button
                        onClick={() => window.dispatchEvent(new CustomEvent('openAiChat'))}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100"
                    >
                        <Bot className="w-4 h-4" />
                        <span>Ask AI Assistant</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Main component that manages both
const WorkflowContext = ({ currentExample, onExampleChange }) => {
    const [showWelcome, setShowWelcome] = useState(false);

    useEffect(() => {
        // Check if this is the first visit
        const hasVisited = localStorage.getItem('laminarPlaygroundVisited');
        if (!hasVisited) {
            setShowWelcome(true);
            localStorage.setItem('laminarPlaygroundVisited', 'true');
        }

        // Listen for manual trigger of welcome dialog
        const handleShowWelcome = () => setShowWelcome(true);
        window.addEventListener('showWelcomeDialog', handleShowWelcome);

        return () => {
            window.removeEventListener('showWelcomeDialog', handleShowWelcome);
        };
    }, []);

    return (
        <>
            <WelcomeDialog
                isOpen={showWelcome}
                onClose={() => setShowWelcome(false)}
            />
            <WorkflowContextHeader
                currentExample={currentExample}
                onExampleChange={onExampleChange}
            />
        </>
    );
};
