import React from 'react';
import Logo from './laminar.svg'; // Ensure the correct path

const NavBar = () => {
  return (
    <nav className="bg-gradient-to-r from-white to-blue-50 p-4 flex items-center justify-between shadow-lg border-b border-blue-100">
      <div className="flex-1 flex items-center justify-start">
        <a href="https://laminar.run" target="_blank" rel="noopener noreferrer" className="transition-transform hover:scale-105">
          <img src={Logo} alt="Laminar Logo" className="h-8 mr-3 mb-1" />
        </a>
        <span className="text-gray-800 font-bold text-xl">Playground</span>
      </div>
      <div className="flex-1 flex justify-center space-x-6">
        <a href="https://docs.laminar.run" target="_blank" rel="noopener noreferrer"
          className="text-gray-700 text-base px-4 py-2 rounded-lg hover:bg-blue-50 transition-colors flex items-center gap-2">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
          </svg>
          Docs
        </a>
        <a href="https://app.laminar.run/" target="_blank" rel="noopener noreferrer"
          className="text-gray-700 text-base px-4 py-2 rounded-lg hover:bg-blue-50 transition-colors flex items-center gap-2">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
          </svg>
          Sign In
        </a>
      </div>
      <div className="flex-1 flex justify-end">
        <a href="https://calendly.com/faiz-laminar/30min" target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Book a Demo</a>
      </div>
    </nav>
  );
}

export default NavBar;
