import React from 'react';

export const DataFlowIndicator = ({ isRunning, isSuccess }) => (
    <div className="absolute inset-0 pointer-events-none">
        <div className={`
      absolute top-1/2 left-0 right-0 h-1 
      ${isRunning ? 'bg-gradient-to-r from-green-200 via-blue-300 to-purple-200' :
                isSuccess ? 'bg-green-200' : 'bg-gray-200'}
    `} />

        {isRunning && (
            <div className="absolute top-1/2 left-0 right-0 -mt-2 flex justify-between">
                <div className="w-4 h-4 bg-white rounded-full border-4 border-green-400 animate-pulse" />
                <div className="w-4 h-4 bg-white rounded-full border-4 border-blue-400 animate-pulse delay-100" />
                <div className="w-4 h-4 bg-white rounded-full border-4 border-purple-400 animate-pulse delay-200" />
            </div>
        )}

        {isSuccess && (
            <div className="absolute top-1/2 left-0 right-0 -mt-2 flex justify-between">
                <div className="w-4 h-4 bg-white rounded-full border-4 border-green-400" />
                <div className="w-4 h-4 bg-white rounded-full border-4 border-green-400" />
                <div className="w-4 h-4 bg-white rounded-full border-4 border-green-400" />
            </div>
        )}
    </div>
); 