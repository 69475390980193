import { ArrowDown, Bot, HelpCircle } from 'lucide-react';
import React from 'react';

export const PanelContainer = ({
    icon: Icon,
    title,
    description,
    isActive,
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    aiSuggestion,
    onHelpClick,
    panelNumber
}) => (
    <div
        className={`
      bg-white rounded-lg shadow-sm overflow-hidden transition-all relative
      ${isActive ? 'ring-2 ring-blue-200' : 'hover:ring-1 hover:ring-gray-200'}
    `}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <div className="p-4 border-b border-gray-200">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                    <div className={`w-8 h-8 rounded-lg flex items-center justify-center ${isActive ? 'bg-blue-100' : 'bg-gray-100'}`}>
                        <Icon className={`w-5 h-5 ${isActive ? 'text-blue-600' : 'text-gray-500'}`} />
                    </div>
                    <div>
                        <h2 className="text-lg font-medium text-gray-900">{title}</h2>
                        <div className="text-sm text-gray-500">{description}</div>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    {panelNumber <= 3 && (
                        <button
                            className="p-1.5 text-gray-400 hover:text-gray-600 hover:bg-gray-50 rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation();
                                onHelpClick(panelNumber);
                            }}
                            title="Show help"
                        >
                            <HelpCircle className="w-4 h-4" />
                        </button>
                    )}
                    {aiSuggestion && (
                        <button
                            className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100"
                            onClick={(e) => {
                                e.stopPropagation();
                                aiSuggestion.onClick();
                            }}
                            title={aiSuggestion.tooltip}
                        >
                            <Bot className="w-4 h-4" />
                            <span>Ask AI</span>
                        </button>
                    )}
                    {isActive && (
                        <div className="flex items-center gap-2 text-xs text-blue-600">
                            <ArrowDown className="w-4 h-4 animate-bounce" />
                        </div>
                    )}
                </div>
            </div>
        </div>
        {children}
    </div>
); 