import { ArrowRight } from 'lucide-react';
import React from 'react';

const titles = [
    "Input Data",
    "Transform",
    "Output",
    "Complete"
];

const descriptions = [
    "Review and prepare your input data",
    "Write or modify your transformation",
    "View and verify the output",
    "Transformation complete!"
];

export const StepIndicator = ({ step, isActive, isComplete, onClick }) => (
    <div
        onClick={onClick}
        className={`
      flex items-center gap-2 p-3 rounded-lg cursor-pointer transition-all
      ${isActive ? 'bg-blue-50 ring-2 ring-blue-200' : 'hover:bg-gray-50'}
    `}
    >
        <div className={`
      w-8 h-8 rounded-full flex items-center justify-center
      ${isActive ? 'bg-blue-100 text-blue-600 ring-2 ring-blue-300' :
                isComplete ? 'bg-green-100 text-green-600' :
                    'bg-gray-100 text-gray-400'}
    `}>
            {isComplete ? '✓' : step}
        </div>
        <div className="flex-1">
            <div className={`font-medium ${isActive ? 'text-blue-900' : 'text-gray-700'}`}>
                {titles[step - 1]}
            </div>
            <div className={`text-xs ${isActive ? 'text-blue-600' : 'text-gray-500'}`}>
                {descriptions[step - 1]}
            </div>
        </div>
        {isActive && (
            <div className="text-blue-600">
                <ArrowRight className="w-5 h-5" />
            </div>
        )}
    </div>
); 